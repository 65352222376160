import './styles/site-styles.scss'
import { D_GET_SIGNED_IN_USER, D_SET_TO_USER_CACHE } from './utilities/action-types'
import { isAuthorized, hasTransportationRole } from './utilities/helpers'
import {
  C_SET_FOOTER_HEIGHT,
  C_SET_SELECTED_TRANSPORTATION_OWNER,
  C_SET_TOOLBAR_HEIGHT
} from './utilities/mutation-types'

let unwatchUser, unwatchRouteNavigation, unwatchSelectedAccount

export default {
  name: 'App',
  data () {
    return {
      drawer: true,
      fixed: false,
      selectedAccount: null,
      transportationOwnersList: [],
      items: [],
      miniVariant: false,
      title: 'Welcome to SmartTransit',
      signedInUser: null,
      displayNavigationAndMenu: false,
      isTransportationOwnersLoading: false,
      transportationOwnersSearch: '',
      searchKeywords: '',
      rootViewKey: Date.now()
    }
  },
  mounted () {
    this.$nextTick(this.onResize)

    unwatchUser = this.$watch(() => (this ? this.$store?.state?.credentials?.user : null), () => {
      this.initUserAndNavigation()
      this.items = _populateItemsByUser.call(this)
    })

    unwatchRouteNavigation = this.$watch(() => (this ? this.$route?.meta?.hideNavigation : null), () => {
      this.initUserAndNavigation()
    })

    unwatchSelectedAccount = this.$watch(() => (this ? this.$store.getters.getSelectedTransportationOwner : null), (newVal) => {
      this.selectedAccount = newVal
      this.items = _populateItemsByUser.call(this)
    })

    setTimeout(() => {
      this.items = _populateItemsByUser.call(this)
    }, 2000)

    const token = this.$store.getters.getToken

    if (token && token.expires > Date.now()) {
      this.$store.dispatch(D_GET_SIGNED_IN_USER).catch(_ => {})
    }
    // if transportation owner detected in route param, then set to user cache, else load from user cache
  },
  methods: {
    onResize () {
      const appToolbarHeight = this.$refs.appToolbar?.computedHeight || 0
      this.$store.commit(C_SET_TOOLBAR_HEIGHT, appToolbarHeight)
      const appFooterHeight = this.$refs.appFooter?.height || 0
      this.$store.commit(C_SET_FOOTER_HEIGHT, appFooterHeight)
    },
    clearSelectedAccount () {
      this.$store.commit(C_SET_SELECTED_TRANSPORTATION_OWNER, null)
      this.$store.dispatch(D_SET_TO_USER_CACHE, { key: 'selectedAccountId', value: null, expiryInSeconds: null })
      this.$router.push({ name: this.$route.name, params: { accountId: 0 } }).catch(_ => {})
      this.forceRootViewRefresh()
    },
    forceRootViewRefresh () {
      this.$data.rootViewKey = Date.now()
    },
    transportationOwnersChange (val) {
      this.currentlySelectedOwner = val
    },
    initUserAndNavigation () {
      this.signedInUser = this.$store?.state?.credentials?.user
      const hideNavigation = this.$route?.meta?.hideNavigation

      if (hideNavigation) {
        this.displayNavigationAndMenu = false
      } else if (this.signedInUser && !hideNavigation) {
        this.displayNavigationAndMenu = true
      } else {
        this.displayNavigationAndMenu = false
      }
    }
  },
  beforeCreate () {
    // this.$store.dispatch(D_GET_SIGNED_IN_USER).catch(_ => {})
  },
  beforeDestroy () {
    if (typeof unwatchUser === 'function') {
      unwatchUser()
    }

    if (typeof unwatchRouteNavigation === 'function') {
      unwatchRouteNavigation()
    }

    if (typeof unwatchSelectedAccount === 'function') {
      unwatchSelectedAccount()
    }
  }
}

function _populateItemsByUser () {
  let items = []
  const user = this.$store?.state?.credentials?.user

  if (user) {
    items = [{
      icon: 'fal fa-sign-out-alt',
      title: 'Sign out',
      routeName: 'signout'
    }]

    // const isInvestor = _.find(user.roles, o => (o.name === 'investor'))
    const isStaff = isAuthorized(user, 'staff')
    const isAdmin = isAuthorized(user, 'admin')
    const isTransporter = isAuthorized(user, 'transporter')
    let isTransporterOwner, isTransporterAdmin

    if (user.transportationAccountUsers) {
      const accountId = Number(this.$router?.currentRoute?.params?.accountId || '0')
      const transportationAccountUser = this.transportationAccountUser || user.transportationAccountUsers.find(o => (o.transportationAccountId === accountId))
      isTransporterOwner = hasTransportationRole(user, 'OWNER', accountId, transportationAccountUser)
      isTransporterAdmin = hasTransportationRole(user, 'ADMIN', accountId, transportationAccountUser)
    }

    const isVendor = isAuthorized(user, 'vendor')
    const accountId = this.selectedAccount ? this.selectedAccount.id : 0
    const route = this.$route || {}

    if (isStaff) {
      let staffChildren = [
        {
          icon: 'fal fa-user-shield',
          title: 'SmartTransit Staff',
          routeName: 'staff'
        },
        {
          icon: 'fal fa-user-tie',
          title: 'Agents',
          routeName: 'agents'
        },
        {
          icon: 'fal fa-users-class',
          title: 'Passengers',
          routeName: 'passengers'
        },
        {
          icon: 'fal fa-route',
          title: 'Routes',
          routeName: 'routes'
        },
        {
          icon: 'fal fa-sign',
          title: 'Bus Stops',
          routeName: 'bus-stops'
        },
        {
          icon: 'fal fa-money-check-edit-alt',
          title: 'Transportation Fares',
          routeName: 'transportation-fares'
        }
      ]

      if (isAdmin) {
        staffChildren.push({
          icon: 'fal fa-gifts',
          title: 'Promotions',
          routeName: 'promotions'
        })
        staffChildren.push({
          icon: 'fal fa-business-time',
          title: 'Hrs Of Operation Templates',
          routeName: 'hours-of-operation-templates'
        })
      }
      items.push({
        icon: 'fal fa-user-shield',
        title: 'Staff Resources',
        routeName: 'staff-resources-group',
        subItems: staffChildren,
        isDefaultOpen: !!((!isTransporter && isStaff) || route.matched.find((o) => (staffChildren.map(o => o.routeName).includes(o.name))))
      })
    }

    if (isStaff || isTransporter) {
      let transportChildren = [
        {
          icon: 'fal fa-users',
          title: 'Accounts',
          routeName: 'transportation-accounts'
        },
        {
          icon: 'fal fa-ticket-alt',
          title: 'Purchased Trips',
          params: { accountId },
          routeName: 'transportation-accounts-purchases'
        },
        {
          icon: 'fal fa-bus',
          title: 'Vehicles',
          params: { accountId },
          routeName: 'transportation-accounts-vehicles'
        },
        {
          icon: 'fal fa-location-circle',
          title: 'Realtime Map',
          params: { accountId },
          routeName: 'transportation-accounts-vehicles-realtime'
        },
        {
          icon: 'fal fa-address-card',
          title: 'Owners/Drivers',
          params: { accountId },
          routeName: 'transportation-accounts-drivers'
        }
      ]

      if (isAdmin || isTransporterOwner || isTransporterAdmin) {
        transportChildren.splice(1, 0, {
          icon: 'fal fa-envelope-open-dollar',
          title: 'Manage Payouts',
          params: { accountId },
          routeName: 'transportation-accounts-payouts'
        })
      }

      items.push({
        icon: 'fal fa-users',
        title: 'Transportation Accounts',
        routeName: 'transportation-accounts-group',
        subItems: transportChildren,
        isDefaultOpen: Boolean((!isStaff && isTransporter) || route.name === 'transportation-accounts' || route.matched.find((o) => (o.name === 'transportation-accounts')))
      })
    }

    if (isVendor) {
      let transportChildren = [
        {
          icon: 'fal fa-money-check-alt',
          title: 'Passenger Transactions',
          routeName: 'vendor-passenger-transactions'
        }
      ]

      items.push({
        icon: 'fal fa-user-headset',
        title: 'Vendor Resources',
        routeName: 'vendor-resources-group',
        subItems: transportChildren,
        isDefaultOpen: Boolean((!isStaff && isVendor) || route.name === 'vendor-passenger-transactions' || route.matched.some((o) => (o.name === 'vendor-passenger-transactions')))
      })
    }
  }
  return items
}
