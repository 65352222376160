import { apiRequests } from '../utilities/axios-factory'
import store from '../store'
import { generateKeywordsQueryForOr } from '@smarttransit/common-client'

function getUser ({ id, include, isDeleted }) {
  include = typeof include === 'string' ? [include] : include
  include = ['roles'].concat(include || [])

  return apiRequests.get('/v1/' + (isDeleted ? 'raw' : '') + '-st-user/' + id + '/join/', {
    params: { filter: { include } },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

function findUsers ({ keywords, timezone, onlyArchived, includeArchived, onlyInvited, page, limit, sort, order }) {
  const offset = page === 1 ? 0 : (page * limit) - limit

  const filter = {
    limit,
    offset,
    where: {},
    include: ['roles']
  }

  if (sort) {
    filter.order = `${sort} ${order.toLowerCase() === 'desc' ? 'DESC' : 'ASC'}`
  }

  if (keywords) {
    filter.where.or = generateKeywordsQueryForOr(keywords, ['firstName', 'lastName', 'email', 'phone'])
  }

  if (onlyInvited) {
    filter.where.verificationToken = { neq: null }
  }

  if (timezone) {
    filter.where.timezone = timezone
  }

  if (onlyArchived) {
    filter.where.dateArchived = { neq: null }
  }

  if (!Object.keys(filter.where).length) {
    delete filter.where
  }

  return apiRequests.get('/v1/' + (includeArchived ? 'raw-' : '') + 'st-user/join/', {
    params: { filter },
    headers: { 'Authorization': store.getters.getTokenId }
  }).then((result) => {
    return result.data
  })
}

export {
  findUsers,
  getUser
}
