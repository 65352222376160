<template>
  <v-flex xs12 sm8 md12>
    <v-card class="elevation-12">
      <v-form @submit.prevent="loginForm">
        <v-layout justify-center>
          <div>
            <img src="@/assets/logo.png" style="width: 250px;" alt="SmartTransit" class="mx-4 mt-4" />
          </div>
        </v-layout>
        <div v-if="!forgotPasswordState">
          <v-card-text>
              <v-layout justify-center class="mb-2">
                <v-btn-toggle v-model="loginType" @change="setLoginTypeBrowserSetting(loginType)">
                  <v-btn title="Login with a mobile phone number" small flat value="mobile">
                    <span>Mobile phone login</span>
                  </v-btn>
                  <v-btn title="Login with an email address" small flat value="email">
                    <span>Email login</span>
                  </v-btn>
                </v-btn-toggle>
              </v-layout>
              <v-text-field v-show="loginType === 'email'" v-model="email" :rules="emailRules" validate-on-blur prepend-icon="fal fa-user" name="email" label="Email address" type="email"></v-text-field>
              <div v-show="loginType === 'mobile'"><phone-number-field v-model="mobile" :child-props="{ label: 'Mobile number' }" /></div>
              <v-text-field id="password" v-model="password" :rules="passwordRules" validate-on-blur prepend-icon="fal fa-lock-alt" name="password" label="Password" type="password"></v-text-field>

            <div class="my-2" v-if="dataErrors.length || (errors && errors.length)">
              <p class="strong">Error(s) occurred:</p>
              <v-alert :value="true" type="error">
                <p :key="key" v-for="(error, key) in getFormErrors">{{ error }}</p>
              </v-alert>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn flat @click="cancel()" color="warning">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat @click="forgotPasswordState = true" color="primary">Forgot Password</v-btn>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="primary">Login</v-btn>
          </v-card-actions>
        </div>
        <div v-if="forgotPasswordState === true">
          <v-card-text>
              <h3 align="center" class="mb-2">Forgot Password</h3>
              <v-layout justify-center class="mb-2">
                <v-btn-toggle v-model="loginType" @change="setLoginTypeBrowserSetting(loginType)">
                  <v-btn title="Send a token link to the mobile number registered" small flat value="mobile">
                    <span>Reset by mobile phone</span>
                  </v-btn>
                  <v-btn title="Send a token link to a registered email address" small flat value="email">
                    <span>Reset by email</span>
                  </v-btn>
                </v-btn-toggle>
              </v-layout>
            <v-text-field v-show="loginType === 'email'" v-model="email" :rules="emailRules" prepend-icon="fal fa-user" name="email" label="Email address" type="email"></v-text-field>
            <phone-number-field v-show="loginType === 'mobile'" v-model="mobile" :child-props="{ label: 'Mobile number' }" />

            <div class="my-2" v-if="dataErrors.length || (errors && errors.length)">
              <p class="strong">Error(s) occurred:</p>
              <v-alert :value="true" type="error">
                <p :key="key" v-for="(error, key) in getFormErrors">{{ error }}</p>
              </v-alert>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn flat @click="cancelForgotPassword()" color="warning">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="primary">Submit</v-btn>
          </v-card-actions>
        </div>
        <div v-if="forgotPasswordState === 'sent'">
          <v-card-text>
            <h3><v-icon v-html="'fal fa-check-circle'" color="success"></v-icon> &nbsp; Password reset request successfully sent</h3>
          </v-card-text>
          <v-card-actions>
            <v-layout justify-center>
              <v-btn @click="backToLogin()" color="primary">Ok</v-btn>
            </v-layout>
          </v-card-actions>
        </div>
      </v-form>
    </v-card>
  </v-flex>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<script>
import src from './src'
export default src
</script>
